import {
  BuildOutlined,
  DashboardOutlined,
  FileExcelOutlined,
  LayoutOutlined,
  LikeOutlined,
  ProjectOutlined,
  ReconciliationOutlined,
  RiseOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { ReactNode, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { useAuthProcess } from 'src/features/auth/useAuthProcess'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { UserInfoModal } from 'src/features/globalNavigation/UserInfoModal'
import { IconReportExport } from 'src/features/globalNavigation/assets/IconReportExport'
import { Divider } from 'src/features/globalNavigation/components/Divider'
import { LogoButton } from 'src/features/globalNavigation/components/LogoButton'
import { NavSection } from 'src/features/globalNavigation/components/NavSection'
import { NavItemExternalLink } from 'src/features/globalNavigation/components/navItem/NavItemExternalLink'
import { NavItemInternalLink } from 'src/features/globalNavigation/components/navItem/NavItemInternalLink'
import { NavItemSubMenuOpener } from 'src/features/globalNavigation/components/navItem/NavItemSubMenuOpener'
import { SubMenuContainer } from 'src/features/globalNavigation/components/subMenuItem/SubMenuContainer'
import { SubMenuDivider } from 'src/features/globalNavigation/components/subMenuItem/SubMenuDivider'
import { SubMenuItemClickable } from 'src/features/globalNavigation/components/subMenuItem/SubMenuItemClickable'
import { SubMenuItemInternalLink } from 'src/features/globalNavigation/components/subMenuItem/SubMenuItemInternalLink'
import * as siderMenuActions from 'src/features/globalNavigation/slice'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'
import { useTogglable } from 'src/hooks/useTogglable'
import { RootState } from 'src/state/store'
import { SanmaPromoBannerWrapper } from 'src/views/components/sanmaPromoteBanner/SanmaPromoteBannerWrapper'

/**
 * グローバルナビゲーションにおいて各アイテムの表示・非表示を管理するための型。
 * 各アイテムの表示・非表示は、契約プランやユーザーロールによって動的に切り替わる。
 */
type ConditionalContent = {
  /**
   * 表示するコンテンツのReactノード
   */
  content: ReactNode
  /**
   * このアイテムがユーザーに対して表示されるべきかどうか
   */
  visible: boolean
}

type Props = {
  children?: ReactNode
  /**
   * このAPIは責務分離の点で問題を抱えているため、近日中に廃止予定です。
   * 新規の利用はしないでください。
   */
  resetPagination?: () => void
}

export const GlobalNavigation = (props: Props) => {
  const { children, resetPagination } = props

  const dispatch = useDispatch()

  const isCollapsed = useSelector(
    (state: RootState) => state.globalNavigationState.isCollapsed,
  )

  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  const { cleanupAndPushOutUser } = useAuthProcess()

  const userInfoModal = useTogglable()

  const toggleCollapse = () => {
    dispatch(siderMenuActions.toggle())
  }

  const userName = localStorage.getItem(localStorageKeys.loginUserName) || ''

  // タブレット横向きよりも画面幅が狭い場合はデフォルトでサイドバーを閉じておく。
  // 参考: https://qiita.com/tomohisaota/items/f8857d01f328e34fb551
  useLayoutEffect(() => {
    if (window.innerWidth < 1024) {
      dispatch(siderMenuActions.close())
    }
  }, [dispatch])

  const mainSectionItems: ConditionalContent[] = [
    {
      content: (
        <NavItemInternalLink
          key="dashboards"
          icon={<DashboardOutlined />}
          title="ダッシュボード"
          internalPath="/dashboards"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('dashboard'),
    },
    {
      content: (
        <NavItemInternalLink
          key="scheduledReportSummary"
          icon={<RiseOutlined />}
          title="実施状況"
          internalPath="/scheduledReportSummary"
          isBeta
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('scheduledReportSummary'),
    },
    {
      content: (
        <NavItemInternalLink
          key="reports"
          icon={<ReconciliationOutlined />}
          title="レポート"
          internalPath="/reports"
          isCollapsed={isCollapsed}
        />
      ),
      visible: isFeatureAvailable('reports'),
    },
    {
      content: (
        <NavItemInternalLink
          key="approvals"
          icon={<LikeOutlined />}
          title="承認"
          internalPath="/approvals"
          isCollapsed={isCollapsed}
        />
      ),
      visible: isFeatureAvailable('approvals'),
    },
    {
      content: (
        <NavItemInternalLink
          key="improves"
          icon={<ProjectOutlined />}
          title="カイゼン"
          internalPath="/improves"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('improves'),
    },
  ]

  const recordSectionItems: ConditionalContent[] = [
    {
      content: (
        <NavItemInternalLink
          key="templates"
          icon={<LayoutOutlined />}
          title="ひな形"
          internalPath="/templates"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('templates'),
    },
    {
      content: (
        <NavItemInternalLink
          key="schedules"
          icon={<ScheduleOutlined />}
          title="スケジュール"
          internalPath="/schedules"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('schedules'),
    },
    {
      content: (
        <NavItemInternalLink
          key="gridVariables"
          icon={<BuildOutlined />}
          title="取り込み項目"
          internalPath="/gridVariables"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('dynamicSheet'),
    },
  ]
  const hasSomeRecordSectionItems = recordSectionItems.some(
    item => item.visible,
  )

  const outputSectionItems: ConditionalContent[] = [
    {
      content: (
        <NavItemInternalLink
          key="excelConversionFlows"
          icon={<FileExcelOutlined />}
          title="Excel変換"
          internalPath="/excelConversionFlows"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('excelConversionRead'),
    },
    {
      content: (
        <NavItemInternalLink
          key="reportExports"
          icon={<IconReportExport />}
          title="レポート出力"
          internalPath="/reportExports"
          isCollapsed={isCollapsed}
          onClick={resetPagination}
        />
      ),
      visible: isFeatureAvailable('reportExports'),
    },
  ]
  const hasSomeOutputSectionItems = outputSectionItems.some(
    item => item.visible,
  )

  // 関数で定義している理由は、サブメニューを閉じる機能を外から注入できるようにするため
  const accountSubMenuItems = (
    closeSubMenu: () => void,
  ): ConditionalContent[] => [
    {
      content: (
        <SubMenuItemClickable
          key="accountInfo"
          title="ユーザー情報"
          onClick={() => {
            userInfoModal.open()
            closeSubMenu()
          }}
        />
      ),
      visible: true,
    },
    {
      content: (
        <SubMenuItemClickable
          key="logout"
          title="ログアウト"
          onClick={() => {
            cleanupAndPushOutUser({ serverRequest: true })
            closeSubMenu()
          }}
        />
      ),
      visible: true,
    },
  ]

  // 関数で定義している理由は、サブメニューを閉じる機能を外から注入できるようにするため
  const settingSubMenuItems = (
    closeSubMenu: () => void,
  ): ConditionalContent[] => [
    {
      content: (
        <SubMenuItemInternalLink
          key="places"
          internalPath="/places"
          onClick={() => {
            resetPagination?.()
            closeSubMenu()
          }}
          title="現場"
        />
      ),
      visible: isFeatureAvailable('places'),
    },
    {
      content: (
        <SubMenuItemInternalLink
          key="placeGroups"
          internalPath="/placeGroups"
          onClick={() => {
            resetPagination?.()
            closeSubMenu()
          }}
          title="現場グループ"
        />
      ),
      visible: isFeatureAvailable('places'),
    },
    {
      content: 'divider',
      visible: true, // 型を分けるのが煩雑なので便宜上書いただけで意味はない
    },
    {
      content: (
        <SubMenuItemInternalLink
          key="users"
          internalPath="/users"
          onClick={() => {
            resetPagination?.()
            closeSubMenu()
          }}
          title="ユーザー"
        />
      ),
      visible: isFeatureAvailable('users'),
    },
    {
      content: (
        <SubMenuItemInternalLink
          key="userGroups"
          internalPath="/userGroups"
          onClick={() => {
            resetPagination?.()
            closeSubMenu()
          }}
          title="ユーザーグループ"
        />
      ),
      visible: isFeatureAvailable('users'),
    },
    {
      content: (
        <SubMenuItemInternalLink
          key="employees"
          internalPath="/employees"
          onClick={() => {
            resetPagination?.()
            closeSubMenu()
          }}
          title="従業員"
        />
      ),
      visible: isFeatureAvailable('employees'),
    },
    {
      content: 'divider',
      visible: true, // 型を分けるのが煩雑なので便宜上書いただけで意味はない
    },
    {
      content: (
        <SubMenuItemInternalLink
          key="companySettings"
          internalPath="/companySettings"
          onClick={() => {
            resetPagination?.()
            closeSubMenu()
          }}
          title="アプリ設定"
        />
      ),
      visible: isFeatureAvailable('companySettings'),
    },
  ]
  const hasSomeSettingSubMenuItems = settingSubMenuItems(() => null).some(
    item => item.content !== 'divider' && item.visible,
  )

  return (
    <div css={styles.container}>
      <div css={[styles.navColumn, isCollapsed && styles.navColumnCollapsed]}>
        <LogoButton isCollapsed={isCollapsed} onClick={toggleCollapse} />

        {/* メインセクション */}
        <div css={styles.sorter}>
          {mainSectionItems.map(item => item.visible && item.content)}
        </div>

        <Divider isCollapsed={isCollapsed} />

        {/* 記録設定セクション */}
        {hasSomeRecordSectionItems && (
          <div css={styles.sorter}>
            <NavSection title={isCollapsed ? ' ' : '記録設定'} />
            {recordSectionItems.map(item => item.visible && item.content)}
          </div>
        )}

        {/* 出力セクション */}
        {hasSomeOutputSectionItems && (
          <div css={[styles.sorter, styles.outputSection]}>
            <NavSection title={isCollapsed ? ' ' : '出力'} />
            {outputSectionItems.map(item => item.visible && item.content)}
          </div>
        )}

        {/* どちらのセクションもない場合にDividerが重複してしまうのを避けるため */}
        {(hasSomeRecordSectionItems || hasSomeOutputSectionItems) && (
          <Divider isCollapsed={isCollapsed} />
        )}

        <div css={styles.sorter}>
          {/* アカウントサブメニュー */}
          <NavItemSubMenuOpener
            icon={<UserOutlined />}
            renderSubmenu={closeSubMenu => (
              <SubMenuContainer>
                {accountSubMenuItems(closeSubMenu).map(
                  item => item.visible && item.content,
                )}
              </SubMenuContainer>
            )}
            title={userName}
            isCollapsed={isCollapsed}
          />

          {/* 設定サブメニュー */}
          {hasSomeSettingSubMenuItems && (
            <NavItemSubMenuOpener
              icon={<SettingOutlined />}
              renderSubmenu={closeSubMenu => (
                <SubMenuContainer>
                  {settingSubMenuItems(closeSubMenu).map((item, index, arr) => {
                    if (item.content !== 'divider') {
                      return item.visible && item.content
                    }

                    // 以下、区切り線を描写するためのコード。
                    // サブメニューの項目はユーザー属性によって表示・非表示がダイナミックに変わるので、
                    // このような手当てをしないとDividerが重複したり不要な場所に描写されたりする。
                    const previousItem = arr[index - 1]
                    const nextItem = arr[index + 1]
                    if (
                      // 前後に項目がある場合のみ区切り線を表示する
                      previousItem &&
                      previousItem.content !== 'divider' &&
                      previousItem.visible &&
                      nextItem &&
                      nextItem.content !== 'divider' &&
                      nextItem.visible
                    ) {
                      return <SubMenuDivider key={index} />
                    }
                    return null
                  })}
                </SubMenuContainer>
              )}
              title="設定"
              isCollapsed={isCollapsed}
            />
          )}

          <NavItemExternalLink
            title="ヘルプページ"
            isCollapsed={isCollapsed}
            externalPath="https://support.kaminashi.com/hc/ja?utm_source=kaminashi_report_product&utm_medium=web&utm_campaign=dashboard"
          />
        </div>

        <div css={styles.spacerForChannelTalk} />
      </div>
      <div css={styles.mainContentColumn}>
        <SanmaPromoBannerWrapper>{children}</SanmaPromoBannerWrapper>
      </div>

      <UserInfoModal
        isOpen={userInfoModal.isOpen}
        onClose={userInfoModal.close}
      />
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
  `,
  navColumn: css`
    background: ${globalNavigationTheme.background};
    color: ${globalNavigationTheme.textColor};
    flex: 0 0 200px;
    height: 100vh;
    overflow-x: hidden;
    overscroll-behavior: none;
    padding-top: 16px;
    position: sticky;
    top: 0;
    scrollbar-width: thin;
  `,
  navColumnCollapsed: css`
    flex-basis: 40px;
  `,
  sorter: css`
    display: grid;
    gap: 4px;
  `,
  outputSection: css`
    margin-top: 16px;
  `,
  spacerForChannelTalk: css`
    height: 100px;
    flex: 0 0 auto;
  `,
  mainContentColumn: css`
    flex: 1 1 auto;
    overflow: hidden;
  `,
}
